import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl-next';
import { useLocation } from 'react-router-dom';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import {
	ExpandableMenuItem,
	ExpandableMenuItemTrigger,
	ExpandableMenuItemContent,
} from '@atlassian/navigation-system/side-nav/expandable-menu-item';
import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';

import { AccessStatus, useSessionData } from '@confluence/session-data';
import { useRouteDataRef, useRouteUrl } from '@confluence/route-manager';
import { CONTEXT_PATH, GLOBAL_PAGE_FORGE } from '@confluence/named-routes';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';
import { useWebItemLocation } from '@confluence/web-item-location';
import { useExtensionList } from '@confluence/forge-ui';
import { ErrorDisplay } from '@confluence/error-boundary';
import type { WithFlagsProps } from '@confluence/flags';
import { withFlags } from '@confluence/flags';

import { AppsIconComponent } from '../../GlobalNavigationIcons';
import { i18n } from '../../globalNavigationTranslations';
import type { GlobalItemProps } from '../globalItemProps';
import { useGlobalItemVisibility } from '../useGlobalItemVisibility';

import { AppsMenuContent } from './AppsMenuContent';
import { AppsMoreActionsMenu } from './AppsMoreActionsMenu';
import { getGlobalAppsParams } from './getGlobalAppsParams';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../../analyticsCallbacks'))
			.fireAppsClickedAnalytics,
);

export const AppsItem = ({ isHidden, peekingId, setPeekingId }: GlobalItemProps) => {
	const { edition, accessStatus } = useSessionData();
	const isAdmin = accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

	const routeDataRef = useRouteDataRef();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const { webItemParams, forgeExtensionParams } = getGlobalAppsParams({
		edition,
		accessStatus,
	});

	const {
		webItems,
		loading: webItemsLoading,
		error: webItemsError,
	} = useWebItemLocation(webItemParams);

	const {
		extensions,
		loading: extensionsLoading,
		error: extensionsError,
	} = useExtensionList(forgeExtensionParams);

	const webItemUrls = webItems.map((webItem) => (webItem.url || '').split('?')[0]);
	const extensionsUrls = extensions.map((extension) =>
		GLOBAL_PAGE_FORGE.toUrl({
			appId: extension.appId,
			envId: extension.environmentId,
			forgeManifestRoute: extension.properties.route,
		}),
	);

	const location = useLocation();
	const isOnAppsRoute = useRouteUrl({
		selector: (routeUrl: string | undefined) => {
			const path = routeUrl || location?.pathname;
			return (
				webItemUrls.some((appUrl) => path.includes(appUrl)) ||
				extensionsUrls.some((appUrl) => path.includes(appUrl)) ||
				path.includes(`${CONTEXT_PATH}/marketplace`)
			);
		},
	});

	const loading = webItemsLoading || extensionsLoading;

	const shouldHide = useGlobalItemVisibility(
		'apps',
		isOnAppsRoute,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	const onClick = (
		_: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>,
		__: UIAnalyticsEvent,
		analyticsAttributes: { isExpanded: boolean },
	) => {
		const { isExpanded } = analyticsAttributes;
		void fireClickAnalytics(isExpanded);
	};

	if (loading) {
		return (
			<MenuButtonItem elemBefore={AppsIconComponent}>
				<FormattedMessage {...i18n.apps} />
			</MenuButtonItem>
		);
	}

	return (
		<>
			<ExpandableMenuItem isDefaultExpanded={isOnAppsRoute}>
				<ExpandableMenuItemTrigger
					elemBefore={AppsIconComponent}
					actionsOnHover={isAdmin && <AppsMoreActionsMenu />}
					onClick={onClick}
				>
					<FormattedMessage {...i18n.apps} />
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>
					<AppsMenuContent loading={loading} webItems={webItems} extensions={extensions} />
				</ExpandableMenuItemContent>
			</ExpandableMenuItem>
			<ErrorFlag webItemsError={webItemsError} extensionsError={extensionsError} />
		</>
	);
};

const ErrorFlag = withFlags(
	({
		webItemsError,
		extensionsError,
		flags,
	}: {
		webItemsError: Error | undefined;
		extensionsError: Error | undefined;
	} & WithFlagsProps) => {
		useEffect(() => {
			if (webItemsError || extensionsError) {
				void flags.showFlag({
					title: <FormattedMessage {...errorI18n.errorTitle} />,
					description: <FormattedMessage {...errorI18n.errorDescription} />,
					type: 'error',
					close: 'auto',
				});
			}
		}, [webItemsError, extensionsError, flags]);

		return (
			<>
				{webItemsError && <ErrorDisplay error={webItemsError} />}
				{extensionsError && <ErrorDisplay error={extensionsError} />}
			</>
		);
	},
);

const errorI18n = defineMessages({
	errorTitle: {
		id: 'side-navigation.global.apps.error-title',
		defaultMessage: 'We couldn’t load apps',
		description: 'Title for the error flag when fetching apps fails',
	},
	errorDescription: {
		id: 'side-navigation.global.apps.error-description',
		defaultMessage: 'Refresh and try again.',
		description: 'Description for the error flag when fetching apps fails',
	},
});
