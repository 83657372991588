import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { useLocation } from 'react-router-dom';

import Skeleton from '@atlaskit/skeleton';
import MarketplaceIcon from '@atlaskit/icon/core/marketplace';
import { Box, Stack } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { MenuList } from '@atlassian/navigation-system/side-nav/menu-list';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import {
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system/side-nav/menu-section';

import type { useWebItemLocationWebItemType as WebItem } from '@confluence/web-item-location';
import { CONTEXT_PATH, GLOBAL_PAGE_FORGE, SITE_ANALYTICS } from '@confluence/named-routes';
import type { ForgeUIGlobalPageExtensionType } from '@confluence/forge-ui';
import { extensionTitle } from '@confluence/forge-ui';
import { fg } from '@confluence/feature-gating';
import { LoadableAfterPaint } from '@confluence/loadable';

import { ANALYTICS_KEY, ANALYTICS_KEY_MODERATE } from './appKeys';
import { AppIcon } from './AppIcon';

const AsyncDiscoverSection = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-apps-menu-recommeded" */
				'./DiscoverSection'
			)
		).DiscoverSection,
});

const DISCOVER_URL = `${CONTEXT_PATH}/marketplace`;

type AppsMenuContentProps = {
	loading: boolean;
	webItems: WebItem[];
	extensions: ForgeUIGlobalPageExtensionType[];
};

export const AppsMenuContent = ({ loading, webItems, extensions }: AppsMenuContentProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireAppsNavigationItemClicked = (itemId: string | null) => () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'navigationItem',
				actionSubjectId: 'appsNavigationMenuItem',
				source: 'globalNavigation',
				attributes: {
					navigationLayer: 'global',
					navigationContainer: 'sidebar',
					navVersion: '4',
					componentType: 'customItem',
					itemType: 'app',
					itemId,
					section: 'yourApps',
				},
			},
		}).fire();
	};

	const location = useLocation();

	const hasApps = webItems.length > 0 || extensions.length > 0;
	const displayApps = hasApps && !loading;

	return (
		<>
			<MenuSection>
				{displayApps && (
					<MenuSectionHeading>
						<FormattedMessage {...i18n.yourApps} />
					</MenuSectionHeading>
				)}
				<MenuList>
					{displayApps && (
						<AppItems
							webItems={webItems}
							extensions={extensions}
							pathname={`${location?.pathname}${location?.search}`}
							fireAppsNavigationItemClicked={fireAppsNavigationItemClicked}
						/>
					)}
					{loading && <LoadingSkeletons />}
				</MenuList>
			</MenuSection>
			{fg('confluence_nav_4_apps_menu_recommend') && <AsyncDiscoverSection />}
			<MenuSection>
				<MenuList>
					<MenuLinkItem
						isSelected={location?.pathname?.includes(DISCOVER_URL)}
						href={DISCOVER_URL}
						elemBefore={<MarketplaceIcon label="" color="currentColor" />}
						onClick={fireAppsNavigationItemClicked('exploreApps')}
					>
						{loading || hasApps ? (
							<FormattedMessage {...i18n.exploreMoreApps} />
						) : (
							<FormattedMessage {...i18n.exploreApps} />
						)}
					</MenuLinkItem>
				</MenuList>
			</MenuSection>
		</>
	);
};

type AppItemsProps = {
	webItems: WebItem[];
	extensions: ForgeUIGlobalPageExtensionType[];
	pathname?: string;
	fireAppsNavigationItemClicked: (itemId: string | null) => () => void;
};

const AppItems = ({
	webItems,
	extensions,
	pathname,
	fireAppsNavigationItemClicked,
}: AppItemsProps) => (
	<>
		{webItems.map(({ id, url, label, icon, completeKey }) => {
			const href = fixWebItemUrl(url, completeKey);
			const isSelected = (pathname && pathname?.includes(href)) || false;
			return (
				<MenuLinkItem
					key={id}
					href={href}
					elemBefore={<AppIcon url={icon?.path} completeKey={completeKey} />}
					isSelected={isSelected}
					onClick={fireAppsNavigationItemClicked(completeKey)}
				>
					{label}
				</MenuLinkItem>
			);
		})}
		{extensions.map((extension) => {
			const href = GLOBAL_PAGE_FORGE.toUrl({
				appId: extension.appId,
				envId: extension.environmentId,
				forgeManifestRoute: extension.properties.route,
			});
			const isSelected = (pathname && pathname?.includes(href)) || false;
			return (
				<MenuLinkItem
					key={extension.key}
					href={href}
					isSelected={isSelected}
					elemBefore={<AppIcon url={extension.properties.icon} />}
				>
					{extensionTitle(extension)}
				</MenuLinkItem>
			);
		})}
	</>
);

const LoadingSkeletons = () => (
	<Box paddingBlockStart="space.100" testId="loading">
		<Stack space="space.100">
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
			<Skeleton width="100%" height="20px" borderRadius="3px" />
		</Stack>
	</Box>
);

const absoluteUrlCheck = new RegExp('^(?:[a-z]+:)?//', 'i');

const fixWebItemUrl = (url: string | null, completeKey: string | null): string => {
	if (!url) {
		return '#';
	}

	url = url.split('?')[0];

	if (completeKey === ANALYTICS_KEY || completeKey === ANALYTICS_KEY_MODERATE) {
		return SITE_ANALYTICS.toUrl();
	}

	if (absoluteUrlCheck.test(url)) {
		return url;
	}

	if (url.startsWith(CONTEXT_PATH)) {
		return url;
	}

	return `${CONTEXT_PATH}${url}`;
};

const i18n = defineMessages({
	yourApps: {
		id: 'side-navigation.global.apps.your-apps',
		defaultMessage: 'Your apps',
		description: 'Label for the section of the global navigation that displays apps',
	},
	exploreApps: {
		id: 'side-navigation.global.apps.explore-apps',
		defaultMessage: 'Explore apps',
		description:
			'Label for the link to find apps. Appears in global navigation when there are no apps installed yet.',
	},
	exploreMoreApps: {
		id: 'side-navigation.global.apps.explore-more-apps',
		defaultMessage: 'Explore more apps',
		description:
			'Label for the link to find apps. Appears in global navigation, underneath already installed apps.',
	},
});
