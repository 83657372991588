import { useEffect } from 'react';

import type { MenuId } from '../useMenuItems';
import { useIsFlyoutOpen } from '../FlyoutStore';

// this hook takes several arguments, and uses them to determine the visibility
// of an element in the Global Navigation, and sets the state of the parent when needed
export const useGlobalItemVisibility = (
	menuId: MenuId,
	isSelected: boolean,
	isHidden?: boolean,
	peekingId?: MenuId, // state from GlobalNavigationComponent
	setPeekingId?: (menuID?: MenuId) => void,
) => {
	const isPeeking = isSelected && isHidden;
	const [isAnyFlyoutPeeking] = useIsFlyoutOpen(peekingId);

	useEffect(() => {
		const peekingInState = peekingId === menuId;
		if (setPeekingId) {
			if (isPeeking && !peekingInState && !isAnyFlyoutPeeking) {
				setPeekingId(menuId);
			}
			if (!isPeeking && peekingInState) {
				setPeekingId(undefined);
			}
		}
	}, [isHidden, peekingId, setPeekingId, menuId, isSelected, isPeeking, isAnyFlyoutPeeking]);
	return !!(isHidden && !isPeeking);
};
